$('.popup').click(function(e) {
	var callbackPopup = $(".popup__container")
	if (!callbackPopup.is(e.target) && callbackPopup.has(e.target).length === 0) {
		$('body').removeClass('no-scroll');
		$(callbackPopup).closest(".popup").removeClass('active');
	}
});

$('.popup__close').click(function(){
	$('body').removeClass('no-scroll');
	$(this).closest('.popup').removeClass('active');
});

$('.product-card__order--1').click(function(){
	$('body').addClass('no-scroll');
	$('.popup-order--1').addClass('active');
});

$('.product-card__order--2').click(function(){
	$('body').addClass('no-scroll');
	$('.popup-order--2').addClass('active');
});

$('.product-card__order--3').click(function(){
	$('body').addClass('no-scroll');
	$('.popup-order--3').addClass('active');
});

$(".cycle-menu__item-btn").click(function() {
	$(".cycle-menu__item-popup").not($(".cycle-menu__item-popup").eq($(this).index(".cycle-menu__item-btn"))).fadeOut();
	$(".cycle-menu__item-popup").eq($(this).index(".cycle-menu__item-btn")).fadeToggle();
});

$(".cycle-menu__item-close").click(function(){
	$(this).closest(".cycle-menu__item-popup").fadeOut();
});

$(document).click(function(e) {
	if ($('.cycle-menu__item-popup').is(":visible")) {
		var callbackPopup = $(".cycle-menu__item-popup, .cycle-menu__item-btn")
		if (!callbackPopup.is(e.target) && callbackPopup.has(e.target).length === 0) {
			$('.cycle-menu__item-popup').fadeOut();
		};
	}
});

//forms
$(".popup-order__form").on("submit", function(){
	let currentForm = $(this);
	$.ajax({
		//url: 'https://nonstoppro.ru/feedback.php',
		url: 'feedback.php',
		method: 'POST',
		dataType: 'json',
		data: $(this).serialize() + '&send=1',
		success: function(data){
			if (data["success"]) {
				$(currentForm).children().hide();
				$(currentForm).append('<div class="form__success">Наша команда скоро свяжется с&nbsp;Вами!</div>');	
			} else {
				$('.form__fields').append('<div class="form__mistakes"><p>Ошибка: ' +
				data["error"] +
				'. Пожалуйста, повторите попытку или свяжитесь с&nbsp;нами. Приносим извинения за&nbsp;неудобства.</p></div>');	
			}
		},
		error: function(e) {
			console.log(e)
		}
	});
	return false;
});

$(".utility__form").on("submit", function(){
	$.ajax({
		//url: 'https://nonstoppro.ru/feedback.php',
		url: 'feedback.php',
		method: 'POST',
		dataType: 'json',
		data: $(this).serialize() + '&send=1',
		success: function(data){
			if (data["success"]) {
				$('.form__main').hide();
				$('.utility__form').append('<div class="form__success">Наша команда скоро свяжется с&nbsp;Вами!</div>');
	
			} else {
				$('.form__fields').append('<div class="form__mistakes"><p>Ошибка: ' +
				data["error"] +
				'. Пожалуйста, повторите попытку или свяжитесь с&nbsp;нами. Приносим извинения за&nbsp;неудобства.</p></div>');	
			}
		},
		error: function(e) {
			console.log(e)
		}
	});
	return false;
}); 